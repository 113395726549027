import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import LightboxModal from "../pages/LightboxModal";
import "../styles/Alaprajzok.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import alapr1 from "../assets/alapr1.jpg";
import alapr2 from "../assets/alapr2.jpg";
import alapr3 from "../assets/alapr3.jpg";
import alapr4 from "../assets/alapr4.jpg";
import alapr5 from "../assets/alapr5.jpg";
import alapr6 from "../assets/alapr6.jpg";
import { LiaBedSolid } from "react-icons/lia";
import { LiaBathSolid } from "react-icons/lia";
import { MdOutlineSpaceDashboard } from "react-icons/md";

AOS.init();

const imageUrlList = [
  alapr1,
  alapr2,
  alapr3,
  alapr4,
  alapr5,
  alapr6
];

const Magnolia = "http://localhost:3000/Magnólia alaprajz.pdf";
const Kokorcsin = "http://localhost:3000/Kokorcsin alaprajz.pdf";
const Hortenzia = "http://localhost:3000/Hortenzia alaprajz.pdf";
const Naspolya = "http://localhost:3000/Naspolya alaprajz.pdf";
const Ökörfavirág = "http://localhost:3000/Ökörfavirág alaprajz.pdf";
const Hóvirág = "http://localhost:3000/Hóvirág alaprajz.pdf";

function Alaprajzok() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const handleDetailsClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  return (
    <div className="alaprajzok">
      <p8>ALAPRAJZOK</p8>
      <div className="keret">
        <Link to="/alapr1" className="alaprajz-link">
          <div className="alaprajz">
            <img
              src={alapr1}
              alt="Alaprajz 1"
              onClick={() => handleImageClick(alapr1)}
            />          </div>
        </Link>
        <div className="alaprajz-text">Magnólia alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 2
          <LiaBathSolid /> 1
          <MdOutlineSpaceDashboard /> 64,47 m² + 14,62 m² terasz
        </p1>
        <Link to="/alapr1">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(0)}
        >
          Részletek
        </button>
        {activeIndex === 0 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>2</p10>
            <p9>Alapterület:</p9>
            <p10>64,47 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>1</p10>
            <p9>Terasz:</p9>
            <p10>14,62 m²</p10>
            <p9></p9>
            <p10></p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Magnolia);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
      <div className="keret">
        <Link to="/alapr2" className="alaprajz-link">
          <div className="alaprajz">
            <img src={alapr2} alt="Alaprajz 2" />
          </div>
        </Link>
        <div className="alaprajz-text">Kökörcsin alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 4
          <LiaBathSolid /> 2
          <MdOutlineSpaceDashboard /> 103,92 m²
        </p1>
        <Link to="/alapr2">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(1)}
        >
          Részletek
        </button>
        {activeIndex === 1 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>4</p10>
            <p9>Alapterület:</p9>
            <p10>103,92 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>2</p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Kokorcsin);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
      <div className="keret">
        <Link to="/alapr3" className="alaprajz-link">
          <div className="alaprajz">
            <img src={alapr3} alt="Alaprajz 3" />
          </div>
        </Link>
        <div className="alaprajz-text">Hortenzia alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 3
          <LiaBathSolid /> 1
          <MdOutlineSpaceDashboard /> 80,68 m² + 18,94 m² terasz
        </p1>
        <Link to="/alapr3">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(2)}
        >
          Részletek
        </button>
        {activeIndex === 2 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>3</p10>
            <p9>Alapterület:</p9>
            <p10>80,68 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>1</p10>
            <p9>Terasz:</p9>
            <p10>18,94 m²</p10>
            <p9></p9>
            <p10></p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Hortenzia);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
      <div className="keret">
        <Link to="/alapr4" className="alaprajz-link">
          <div className="alaprajz">
            <img src={alapr4} alt="Alaprajz 4" />
          </div>
        </Link>
        <div className="alaprajz-text">Naspolya alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 3
          <LiaBathSolid /> 1
          <MdOutlineSpaceDashboard /> 96,81 m²
        </p1>
        <Link to="/alapr4">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(3)}
        >
          Részletek
        </button>
        {activeIndex === 3 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>3</p10>
            <p9>Alapterület:</p9>
            <p10>96,81 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>1</p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Naspolya);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
      <div className="keret">
        <Link to="/alapr5" className="alaprajz-link">
          <div className="alaprajz">
            <img src={alapr5} alt="Alaprajz 5" />
          </div>
        </Link>
        <div className="alaprajz-text">Ökörfavirág alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 4
          <LiaBathSolid /> 2
          <MdOutlineSpaceDashboard /> 100,12 m²
        </p1>
        <Link to="/alapr5">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(4)}
        >
          Részletek
        </button>
        {activeIndex === 4 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>4</p10>
            <p9>Alapterület:</p9>
            <p10>100,12 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>2</p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Ökörfavirág);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
      <div className="keret">
        <Link to="/alapr6" className="alaprajz-link">
          <div className="alaprajz">
            <img src={alapr6} alt="Alaprajz 6" />
          </div>
        </Link>
        <div className="alaprajz-text">Hóvirág alaprajz</div>
        <p1 className="alaprajz-ikon">
          <LiaBedSolid /> 4
          <LiaBathSolid /> 2
          <MdOutlineSpaceDashboard /> 122,83 m²
        </p1>
        <Link to="/alapr6">
          <button className="alaprajz-nagy">Alaprajz</button>
        </Link>
        <button
          className="alaprajz-nagy"
          onClick={() => handleDetailsClick(5)}
        >
          Részletek
        </button>
        {activeIndex === 5 && (
          <div className="Reszletek">
            <p9>Kivitel:</p9>
            <p10> Egyszintes</p10>
            <p9>Hálószoba:</p9>
            <p10>4</p10>
            <p9>Alapterület:</p9>
            <p10>122,83 m²</p10>
            <p9>Fürdőszoba:</p9>
            <p10>2</p10>
            <button
              className="let"
              onClick={() => {
                downloadFileAtURL(Hóvirág);
              }}
            >
              Teljes alaprajz letöltése
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Alaprajzok;
