import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Tam from "../assets/tamogatas.jpg";
import Av from "../assets/avant1.jpg";
import Ku from "../assets/kubota1.jpg";
import La from "../assets/la.jpg";
import Na from "../assets/na.jpg";

AOS.init();

function Tamogatas() {
    return (
        <body>
            <div className="tam1">
            <Link to="/tamogatas">
             <img src={Tam} alt="Tamogatas" />
            </Link>
            </div>
        <div data-aos="fade-left"
        data-aos-offset="50"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keret">
        <h2>Széchenyi Terv Plusz támogatás</h2>
          <p33 className="szo">Kedvezményezett neve: Red-Home Bau Szolgáltató Korlátolt Felelősségű Társaság Fejlesztés tárgya, 
          </p33>
          <p33 className="szo">
          célja: A Red-Home Bau Kft. Kecskeméti telephelyének modernizálása
          </p33>
          <p33 className="szo">
          Szerződött támogatás összege: 48.088.922 Ft 
          </p33>
          <p33 className="szo">
          Projekt azonosítószáma: GINOP_PLUSZ-1.2.1-21-2021- 01396 
          </p33>
          <p33 className="szo">
          Feltételesen visszatérítendő támogatás százalékos mértéke: 70% 
          </p33>
          <p33 className="szo">
          Projekt Tényleges kezdete: 2022.01.21.
          </p33>
          <p33 className="szo">
          Projekt tényleges fizikai befejezése 2023.07.21.
          </p33>
          <div className="projektkep4">
                <img src={Av} alt="Av" />
                <img src={Ku} alt="Ku" />

        </div>
        <p88 className="szo">. </p88>
        </div>
        </div>
        <div data-aos="zoom-in"
        data-aos-offset="50"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keret">
        <h2>Támogatás részletei</h2>
          <p33 className="szo">A Red-Home Bau Kft. -A mikro-, kis- és középvállalkozások modern üzleti és termelési kihívásokhoz
             való alkalmazkodását segítő fejlesztések támogatása a leghátrányosabb helyzetű régiókban című pályázati kiírás keretében
              48,09 millió Ft feltételesen vissza nem térítendő európai uniós támogatásban részesült. A támogatást a Társaság a 
              Gazdaságfejlesztési és Innovációs Operatív Program Plusz keretében nyerte el. A fejlesztés célja a cég Kecskemét Kőrösi hegy 36. 
              telephelyén lévő  60 m2-es gazdasági épületet átalakítása, és a megnövekedett megrendeléseink kielégítését két új földmunkagép, 
              illetve egy vakológép beszerzésével segítik elő. Az irodai munkához laptopokat és egy nagy teljesítményű nyomtatót szerez be. 
              A projekt során a következő eszközök kerülnek beszerzésre:  1 db Avant 860i Univerzális minirakodó, 1 db Kubota U36-4 KGLS2 típusú,
               zárt kezelőfülkés gumilánctalpas minikotró, 1 dbTurbosol Giotto Mono230V zsákos anyagok felhordására alkalmas vakológép, 2 db Lenovo 
               ThinkBook 15 G2 Notebook, 1 db RICOH IM C2000SP Színes A/3-as digitális, multifunkciós másológép. A projekt során 5 Kwp Napelemes rendszer 
               kerül felszerelése a tetőszerkezetre, amely a fémmegmunkáló műhely energiaigényét fogja kielégíteni.
             A cég ügyvezetője, a pályázathoz kapcsolódó képzésen, tanácsadáson vesz részt  </p33>
          <div className="projektkep4">
          <img src={La} alt="La" />
          <img src={Na} alt="Na" />

          <p88 className="szo">. </p88>
          <p88 className="szo">. </p88>
          <p88 className="szo">. </p88>
          <p88 className="szo">. </p88>
          <p88 className="szo">. </p88>
          </div>          <p88 className="szo">. </p88>



        </div>     
        <p88 className="szo">. </p88>
        <p88 className="szo">. </p88>
        </div>
  
      </body>


    );
}

export default Tamogatas;

