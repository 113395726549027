import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../styles/Galeria.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ClickableImage from "./ClickableImage";
import haz from "../assets/magnolia1.jpg";
import haz1 from "../assets/magnolia4.jpg";
import haz2 from "../assets/magnolia3.jpg";
import haz3 from "../assets/hortenzia1.png";
import haz5 from "../assets/hortenzia111.jpg";
import haz6 from "../assets/hortenzia11.jpg";
import haz21 from "../assets/vandor5.jpg";
import haz22 from "../assets/vandor1.jpg";
import haz23 from "../assets/vandor2.jpg";




import haz7 from "../assets/hortenzia22.jpg";
import haz8 from "../assets/hortenzia2.jpg";
import haz9 from "../assets/kokorcsin11.jpg";
import haz10 from "../assets/kokorcsin1.jpg";
import haz11 from "../assets/vadalma1.jpg";
import haz12 from "../assets/vadalma1111.jpg";
import haz13 from "../assets/vadalma11.jpg";
import haz14 from "../assets/vadalma111.jpg";
import haz15 from "../assets/magnolia2.jpg";
import haz16 from "../assets/magnolia22.jpg";
import haz17 from "../assets/magnolia222.jpg";
import haz18 from "../assets/magnolia2222.jpg";
import haz19 from "../assets/magnolia22222.jpg";
import haz20 from "../assets/magnolia222222.jpg";
import kerites from "../assets/kerites1.jpg";
import kerites1 from "../assets/kerites11.jpg";
import in1 from "../assets/magnolia44.jpg";
import in2 from "../assets/magnolia444.jpg";
import in3 from "../assets/magnolia4444.jpg";
import in4 from "../assets/magnolia44444.jpg";
import in5 from "../assets/magnolia444444.jpg";
import in6 from "../assets/magnolia4444444.jpg";
import in12 from "../assets/ajto.jpg";
import in13 from "../assets/ajto2.jpg";
import in14 from "../assets/furdo1.jpg";
import in15 from "../assets/furdo2.jpg";
import in16 from "../assets/furdo3.jpg";
import in17 from "../assets/konyha1.jpg";
import in18 from "../assets/konyha2.jpg";

import in7 from "../assets/talfaja1.jpg";
import in8 from "../assets/talfaja11.jpg";
import in9 from "../assets/talfaja111.jpg";
import in10 from "../assets/talfaja1111.jpg";
import in11 from "../assets/talfaja11111.jpg";

AOS.init();

const slides = [
  { src: haz,  className:"img"},
  { src: haz1 },
  { src: haz2 },
  { src: haz3 },
  { src: haz5 },
  { src: haz6 },
  { src: haz21 },
  { src: haz22 },
  { src: haz23 },
  { src: haz7 },
  { src: haz8 },
  { src: haz9 },
  { src: haz10 },
  { src: haz11 },
  { src: haz12 },
  { src: haz13 },
  { src: haz14 },
  { src: haz15 },
  { src: haz16 },
  { src: haz17 },
  { src: haz18 },
  { src: haz19 },
  { src: haz20 },
  { src: kerites },
  { src: kerites1 },
  { src: in1 },
  { src: in2 },
  { src: in3 },
  { src: in4 },
  { src: in5 },
  { src: in6 },
  { src: in12 },
  { src: in13 },
  { src: in14 },
  { src: in15 },
  { src: in16 },
  { src: in17 },
  { src: in18 },
  { src: in7 },
  { src: in8 },
  { src: in9 },
  { src: in10 },
  { src: in11 },
];


export default function Galeria() {
  const [index, setIndex] = useState(-1);
  

  return (
    <>
    
      <div className="img">
        {/* Minden kép megjelenítése a galériában */}
        {slides.map((slide, index) => (
          <ClickableImage
            key={index}
            src={slide.src}
            alt={slide.alt}
            onClick={() => setIndex(index)} // A kattintás kezelése a ClickableImage komponensben
            className="custom-lightbox"
            />
        ))}
      </div>

      {/* A PhotoAlbum és a Lightbox komponensek továbbra is ugyanazok */}
      <PhotoAlbum
        className="img"
        photos={slides}
        targetRowHeight={150}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        className="custom-lightbox"
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, ]}
        
      />
    </>
  );
}