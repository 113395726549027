import React, { useState } from "react";
import "../styles/Projektek.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import vadalma1 from "../assets/alapr2.jpg";
import vandor2 from "../assets/vandor2.jpg";
import janus1 from "../assets/janus1.jpg";
import vandor3 from "../assets/vandor3.jpg";

AOS.init();

const Projektek = () => {
  return (
    <div className="projektek-container">
      <div data-aos="zoom-in" data-aos-delay="100" data-aos-offset="200" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
        <h2>Projektek</h2>
      </div>

      {/* Magnólia Projekt */}
      <div className="projekt-item" data-aos="flip-left" data-aos-delay="100" data-aos-offset="250" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
        <div className="keret2">
          <div className="iras" data-aos="fade-right" data-aos-delay="0" data-aos-offset="250" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
            <h2>Magnólia</h2>
            <p33 className="description">Ez a ház Kecskemét vonzáskörzetében található.</p33>
            <p34 className="description2">Bővebb információkért kérem kattintson a képre!</p34>
          </div>
          <div className="jobbkep" data-aos="fade-left" data-aos-delay="0" data-aos-offset="215" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
            <Link to="/haz1">
              <img src={vadalma1} alt="Magnólia" />
            </Link>
          </div>
        </div>
      </div>

      {/* Kökörcsin Projekt */}
      <div className="projekt-item" data-aos="flip-left" data-aos-delay="100" data-aos-offset="250" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
        <div className="keret2">
          <div className="iras" data-aos="fade-right" data-aos-delay="0" data-aos-offset="250" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
            <h2>Kökörcsin</h2>
            <p33 className="description">Ez a ház Kecskemét vonzáskörzetében található.</p33>
            <p34 className="description2">Bővebb információkért kérem kattintson a képre!</p34>
          </div>
          <div className="jobbkep" data-aos="fade-left" data-aos-delay="0" data-aos-offset="215" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="false">
            <Link to="/haz2">
              <img src={vandor2} alt="Kökörcsin" />
            </Link>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Projektek;